import request from '@/utils/request'


// 查询用户信息列表
export function listUser(query) {
  return request({
    url: '/biz/user/list',
    method: 'get',
    params: query
  })
}

// 查询用户信息分页
export function pageUser(query) {
  return request({
    url: '/biz/user/page',
    method: 'get',
    params: query
  })
}

// 查询用户信息详细
export function getUser(data) {
  return request({
    url: '/biz/user/detail',
    method: 'get',
    params: data
  })
}

// 新增用户信息
export function addUser(data) {
  return request({
    url: '/biz/user/add',
    method: 'post',
    data: data
  })
}

// 修改用户信息
export function updateUser(data) {
  return request({
    url: '/biz/user/edit',
    method: 'post',
    data: data
  })
}

// 删除用户信息
export function delUser(data) {
  return request({
    url: '/biz/user/delete',
    method: 'post',
    data: data
  })
}
// 解冻、冻结用户提现
export function withdrawalFreezeUser(data) {
  return request({
    url: '/biz/user/withdrawalFreezeUser',
    method: 'post',
    data: data
  })
}

export function updateUserNo(data) {
  return request({
    url: '/biz/user/updateUserNo',
    method: 'post',
    data: data
  })
}

// 封禁解禁注销找回用户信息
export function updateStatus(data) {
  return request({
    url: '/biz/user/updateStatus',
    method: 'post',
    data: data
  })
}
